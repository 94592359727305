import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 0,
  "title": "Single Sign-on",
  "subtitle": "How to control the session behavior of your Criipto Verify domain"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Enabling Single Sign-On, SSO, for a Criipto Verify domain will let your users get access to several of your systems with a minimum of effort. They will only be prompted for a login once per browsing session when you are using SSO. Subsequent authentication requests on the same domain (and for the same eID method) will be completed automatically by Criipto Verify, thereby reducing the UX friction.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`The session information needed to maintain an SSO session is maintained in `}<inlineCode parentName="p">{`HTTP-only`}</inlineCode>{`, `}<inlineCode parentName="p">{`Secure`}</inlineCode>{` cookies, created with `}<inlineCode parentName="p">{`SameSite=None`}</inlineCode>{`. This means that the session can be established and maintained even if your users access your Criipto Verify domain in third-party context. Cookies and therefore also the SSO session is tied to a single Criipto Verify domain.`}</p>
    </Highlight>
    <h2>{`How to enable Single Sign-on`}</h2>
    <p>{`You enable and disable `}<inlineCode parentName="p">{`SSO`}</inlineCode>{` per DNS domain through the management dashboard for Criipto Verify. Go to the `}<inlineCode parentName="p">{`Domains`}</inlineCode>{` tab on `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/domains"
      }}>{`dashboard.criipto.com`}</a>{` and click on the domain you choose for SSO. The following options are available:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "37.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA/UlEQVQoz41R20rDQBDdjxR8FD9A8AMEER/FXxPboGG7N9Ntu8mmKSZp9PHITGMoMaU+HGZnhnPm7IzYOYcsSVAohcZ71MvlJBqC939AvXa1wjp9h5vPIXbWws1mLEiNKdIxeQrEC1JinaYQ+yKiy3OQ06g1SmOGWGp9eGvNfSJ/TiHLeCAJi64s0YSAylpEpZi8NQZba1mUnOdSorLuX+5ZsGVBg2KxYEdRHVxRXjmHfb+Kk/vtRVvvIboYWZC+RM7IKYHzPh4T69FxhryPos6yoTg+SjsmjUC7+w4B8uUVl9e3uLi6gajPXPCUs9/a12aDj+QN94/PuHt4wg/2+0jhp9JnTwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Single Sign-On Settings",
          "title": "Single Sign-On Settings",
          "src": "/static/e3f8602e12994e4874763be20dfe9e41/e5715/sso-settings-for-domain.png",
          "srcSet": ["/static/e3f8602e12994e4874763be20dfe9e41/8514f/sso-settings-for-domain.png 192w", "/static/e3f8602e12994e4874763be20dfe9e41/804b2/sso-settings-for-domain.png 384w", "/static/e3f8602e12994e4874763be20dfe9e41/e5715/sso-settings-for-domain.png 768w", "/static/e3f8602e12994e4874763be20dfe9e41/0955e/sso-settings-for-domain.png 901w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`Per-request options`}</h2>
    <p>{`You can tweak this behavior on a per-authentication-request basis by specifying an additional query parameter in the request for authentication. There are 2 options: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Silent check`}</strong>{`: Check if the user has an existing session. Criipto Verify will never require any user interactions in this case. You specify this by adding an extra query parameter `}<inlineCode parentName="li">{`prompt=none`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Forced login`}</strong>{`: Ignore an existing session and force a new login. You specify this option by adding an extra query parameter `}<inlineCode parentName="li">{`prompt=login`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`If you send a `}<inlineCode parentName="p">{`prompt=none`}</inlineCode>{` and the user has no session, an error response will be sent back to your supplied callback URL with an `}<inlineCode parentName="p">{`error=login_required`}</inlineCode>{` value.`}</p>
    <p><em parentName="p">{`Note`}</em>{` that if you are using WS-Federation, and have to use a library that supports only standard parameters, you can achieve the same effect via the `}<inlineCode parentName="p">{`wfresh`}</inlineCode>{` parameter. Specify `}<inlineCode parentName="p">{`wfresh=-1`}</inlineCode>{` for a `}<inlineCode parentName="p">{`Silent check`}</inlineCode>{` and `}<inlineCode parentName="p">{`wfresh=0`}</inlineCode>{` for a `}<inlineCode parentName="p">{`Forced login`}</inlineCode></p>
    <h2>{`Single LogOut (SLO)`}</h2>
    <p>{`If you are using SSO across multiple applications, you should also offer users a way to actively terminate the sessions with each application. Depending on the authentication protocol you use, you can initiate a `}<em parentName="p">{`Single Logout`}</em>{`, SLO, flow by sending the users browser to one of the following URLs:`}</p>
    <ul>
      <li parentName="ul">{`OpenID Connect: `}<inlineCode parentName="li">{`https://VERIFY_DOMAIN/oauth2/logout?post_logout_redirect_uri=a_pre-registered_callback_url`}</inlineCode></li>
      <li parentName="ul">{`WS-Federation: `}<inlineCode parentName="li">{`https://VERIFY_DOMAIN/passive/federation?wa=wsignout1.0&wreply=a_pre-registered_callback_url`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      